import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import PageMenu from '../../../components/PageMenu/PageMenu';
import mortgageResourcesMenu from '../../../data/page-menus/mortgage-resources';

import PrepaymentCalculator, {
  PrepaymentRateRanges,
} from '../../../components/PrepaymentCalculator/PrepaymentCalculator';

import { depositRates } from '../../../data/deposits/current-deposit-rates';
import './style.scss';

type Props = PageProps & WithTranslation;

const PrepaymentCalculator2016: React.FC<Props> = (props) => {
  const { t } = props;
  const rateRanges: PrepaymentRateRanges[] = [
    {
      monthsLabel: `1-17 ${t(`${LocaleNameSpaceKeys.COMMON}:months`)}`,
      postedYear: `1 ${t(`${LocaleNameSpaceKeys.COMMON}:year`)}`,
      rate: depositRates[0].maturity.value,
      minMonths: 1,
      maxMonths: 17,
    },
    {
      monthsLabel: `18-29 ${t(`${LocaleNameSpaceKeys.COMMON}:months`)}`,
      postedYear: `2 ${t(`${LocaleNameSpaceKeys.COMMON}:year`)}`,
      rate: depositRates[1].maturity.value,
      minMonths: 18,
      maxMonths: 29,
    },
    {
      monthsLabel: `30-41 ${t(`${LocaleNameSpaceKeys.COMMON}:months`)}`,
      postedYear: `3 ${t(`${LocaleNameSpaceKeys.COMMON}:year`)}`,
      rate: depositRates[2].maturity.value,
      minMonths: 30,
      maxMonths: 41,
    },
    {
      monthsLabel: `${t('prepaymentCalculator.greaterThanEqualTo')} 42 ${t(
        `${LocaleNameSpaceKeys.COMMON}:months`
      )}`,
      postedYear: `4 ${t(`${LocaleNameSpaceKeys.COMMON}:year`)}`,
      rate: depositRates[3].maturity.value,
      minMonths: 42,
      maxMonths: Infinity,
    },
  ];

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(`prepaymentCalculator.seo.title`),
    },
    page: {
      title: t(`prepaymentCalculator.heading`),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-12.jpg',
          alt: 'Image',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="PrepaymentCalculator2016">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <PrepaymentCalculator rates={rateRanges} />
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(PrepaymentCalculator2016);
