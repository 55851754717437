import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './style.scss';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import Input, { InputType } from '../primitives/Input/Input';
import { formatCurrency, formatPercent } from '../../shared/utils/formatNumbers';
import CommonData from '../../data/common/common';
import ExternalLink from '../ExternalLink/ExternalLink';
import { useLocaleContext } from '../../context/Locale';
import Button from '../primitives/Button/Button';
import Link from '../Link/Link';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';

export interface PrepaymentRateRanges {
  monthsLabel: string;
  postedYear: string;
  rate: number;
  minMonths: number;
  maxMonths: number;
}
interface OwnProps {
  className?: string;
  rates: PrepaymentRateRanges[];
}

type Props = OwnProps;

const prepaymentCalculator: React.FC<Props> = ({ rates, className = '' }) => {
  const { t } = useTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE);

  const { activeLocaleTag } = useLocaleContext();

  const initialFormState = {
    remainingPrincipalBalance: {
      value: 0,
      isInvalid: false,
      validationMessage: t('prepaymentCalculator.form.remainingPrincipalBalance.validationMessage'),
    },
    currentInterestRate: {
      value: 0,
      isInvalid: false,
      validationMessage: t('prepaymentCalculator.form.currentInterestRate.validationMessage'),
    },
    years: 0,
    months: 0,
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [prepaymentAmount, setPrepaymentAmount] = useState(0);

  const isAmountValid = (value: number): boolean => {
    if (value > 0) {
      return true;
    }

    return false;
  };

  const handleFormChange = (value: string, id: string) => {
    const parsedNum = +value || 0;

    switch (id) {
      case 'remainingPrincipalBalance':
        setFormValues((prevState) => ({
          ...prevState,
          remainingPrincipalBalance: {
            ...prevState.remainingPrincipalBalance,
            value: parsedNum,
          },
        }));
        break;

      case 'currentInterestRate':
        setFormValues((prevState) => ({
          ...prevState,
          currentInterestRate: {
            ...prevState.currentInterestRate,
            value: parsedNum,
          },
        }));
        break;
      case 'years':
        setFormValues((prevState) => ({
          ...prevState,
          years: parsedNum,
        }));
        break;
      case 'months':
        setFormValues((prevState) => ({
          ...prevState,
          months: parsedNum,
        }));
        break;

      default:
        if (process.env.NODE_ENV === 'development') {
          console.error(
            `${id} does not exist on Contact us form. Please check form on '/src/pages/contact-us/' page`
          );
        }
        break;
    }
  };

  const calculateAmount = (event: any) => {
    event.preventDefault();
    const monthTotal = formValues.years * 12 + formValues.months;

    const formValueStateCopy = { ...formValues };

    formValueStateCopy.remainingPrincipalBalance.isInvalid = !isAmountValid(
      formValueStateCopy.remainingPrincipalBalance.value
    );

    formValueStateCopy.currentInterestRate.isInvalid = !isAmountValid(
      formValueStateCopy.currentInterestRate.value
    );

    if (
      formValueStateCopy.remainingPrincipalBalance.isInvalid ||
      formValueStateCopy.currentInterestRate.isInvalid
    ) {
      setFormValues(formValueStateCopy);
      let inputField;

      if (formValueStateCopy.remainingPrincipalBalance.isInvalid) {
        inputField = document.getElementById('remainingPrincipalBalance');
      } else {
        inputField = document.getElementById('currentInterestRate');
      }

      if (inputField) {
        (inputField as HTMLElement).focus();
      }

      return false;
    }

    const threeMonthInterestPayment =
      ((formValueStateCopy.currentInterestRate.value / 100) *
        formValueStateCopy.remainingPrincipalBalance.value) /
      4;

    // This should always find a rate because of "Infinity" as one of the max months
    const foundRate = rates.find(
      (rate) => monthTotal >= rate.minMonths && monthTotal <= rate.maxMonths
    );

    let amount = 0;

    if (foundRate) {
      const interestDifferencePayment =
        ((formValueStateCopy.currentInterestRate.value / 100 - foundRate?.rate / 100) *
          formValueStateCopy.remainingPrincipalBalance.value *
          monthTotal) /
        12;

      amount = Math.max(threeMonthInterestPayment, interestDifferencePayment);
    }

    setFormValues(formValueStateCopy);
    setPrepaymentAmount(amount);
    return false;
  };

  const getRatesRows = () => {
    return rates.map((rate, i) => {
      return (
        <tr key={`rates-${i}`}>
          <td>{rate.monthsLabel}</td>
          <td>{rate.postedYear}</td>
          <td>{formatPercent(rate.rate, activeLocaleTag)}</td>
        </tr>
      );
    });
  };

  const getMortgageServicingTollFreeNumber = () => (
    <ExternalLink
      href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
      aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
        CommonData.tollFreeCustomerServiceNumber.mortgageServicing
      }`}
    />
  );
  const getTorontoFirstNumber = () => (
    <ExternalLink
      href={`tel:${CommonData.telephoneNumber.toronto.first}`}
      aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`)} ${
        CommonData.telephoneNumber.toronto.first
      }`}
    />
  );

  return (
    <div className={`PrepaymentCalculator ${className}`}>
      <form onSubmit={calculateAmount}>
        <p>{t('prepaymentCalculator.form.heading')}</p>
        <Input
          id="remainingPrincipalBalance"
          className="PrepaymentCalculator__generic-gap"
          isInvalid={formValues.remainingPrincipalBalance.isInvalid}
          validationMessage={formValues.remainingPrincipalBalance.validationMessage}
          options={{
            type: InputType.NUMBER,
            label: t('prepaymentCalculator.form.remainingPrincipalBalance.label'),
            inputProps: {
              placeholder: '0',
              min: 0,
              maxLength: 10000000000000,
              step: 'any',
              pattern: '[0-9]*',
              inputMode: 'decimal',
            },
            groupOptions: {
              rightSide: {
                text: '$',
              },
            },
          }}
          required
          onChange={handleFormChange}
        />
        <Input
          id="currentInterestRate"
          className="PrepaymentCalculator__generic-gap"
          isInvalid={formValues.currentInterestRate.isInvalid}
          validationMessage={formValues.currentInterestRate.validationMessage}
          options={{
            type: InputType.NUMBER,
            label: t('prepaymentCalculator.form.currentInterestRate.label'),
            inputProps: {
              placeholder: '0.0',
              min: 0,
              maxLength: 10000000000000,
              step: 'any',
              pattern: '[0-9]*',
              inputMode: 'decimal',
            },
            groupOptions: {
              rightSide: {
                text: '%',
              },
            },
          }}
          required
          onChange={handleFormChange}
        />
        <h2 className="h5 PrepaymentCalculator__terms-remaining">
          {t('prepaymentCalculator.form.termRemaining')}
        </h2>
        <Input
          id="years"
          className="PrepaymentCalculator__generic-gap"
          options={{
            type: InputType.NUMBER,
            label: t(`${LocaleNameSpaceKeys.COMMON}:year`, { count: 2 }),
            inputProps: {
              placeholder: '0',
              min: 0,
              maxLength: 10000000000000,
              step: 'any',
              pattern: '[0-9]*',
              inputMode: 'decimal',
            },
          }}
          onChange={handleFormChange}
        />
        <Input
          id="months"
          className="PrepaymentCalculator__generic-gap"
          options={{
            type: InputType.NUMBER,
            label: t(`${LocaleNameSpaceKeys.COMMON}:months`),
            inputProps: {
              placeholder: '0',
              min: 0,
              maxLength: 10000000000000,
              step: 1,
              pattern: '[0-9]*',
              inputMode: 'decimal',
            },
          }}
          onChange={handleFormChange}
        />
        <Button
          id="calculate"
          className="PrepaymentCalculator__generic-gap"
          type="submit"
          styleOptions={{ isInline: true }}
        >
          {t(`${LocaleNameSpaceKeys.COMMON}:calculate`)}
        </Button>
      </form>
      <p>
        <Trans i18nKey="prepaymentCalculator.form.footerNote" t={t} />
      </p>
      <div className="PrepaymentCalculator__result PrepaymentCalculator__generic-gap" role="status">
        <p>{t('prepaymentCalculator.form.resultBlock')}</p>
        <span className="h3">{formatCurrency(prepaymentAmount, activeLocaleTag)}</span>
      </div>
      <p className="PrepaymentCalculator__generic-gap">
        <Trans
          i18nKey="prepaymentCalculator.infoAboutCharge"
          t={t}
          values={{
            localNumber: CommonData.telephoneNumber.toronto.first,
            tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
          }}
        >
          {getTorontoFirstNumber()}
          {getMortgageServicingTollFreeNumber()}
        </Trans>
      </p>
      <table className="PrepaymentCalculator__generic-gap">
        <caption>{t('prepaymentCalculator.tableHeading')}</caption>
        <thead>
          <tr>
            <th>{t('prepaymentCalculator.remainingTermIs')}</th>
            <th>{t('prepaymentCalculator.currentPostedReInvestment')}</th>
            <th>{t('prepaymentCalculator.currentRate')}</th>
          </tr>
        </thead>

        <tbody>{getRatesRows()}</tbody>
      </table>
      <p className="PrepaymentCalculator__generic-gap">
        <Trans
          i18nKey="prepaymentCalculator.understandingYourMortgageGuide"
          t={t}
          values={{
            localNumber: CommonData.telephoneNumber.toronto.first,
            tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
          }}
        >
          <Link to={getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.UNDERSTANDING_YOUR_MORTGAGE)} />
          <ExternalLink href={CommonData.websites.fcacMortgages[activeLocaleTag]} />
          {getTorontoFirstNumber()}
          {getMortgageServicingTollFreeNumber()}
        </Trans>
      </p>
      <div className="PrepaymentCalculator__generic-gap">
        <h2>{t('prepaymentCalculator.howPaymentChargesAreCalculated.heading')}</h2>
        <p>{t('prepaymentCalculator.howPaymentChargesAreCalculated.description')}</p>
        <h3 className="h5">
          {t('prepaymentCalculator.howPaymentChargesAreCalculated.threeMonthsFormula.heading')}
        </h3>
        <p>
          <Trans
            i18nKey="prepaymentCalculator.howPaymentChargesAreCalculated.threeMonthsFormula.body"
            t={t}
          />
        </p>
        <br />
        <p className="h5">{t('prepaymentCalculator.or')}</p>
        <br />
        <h3 className="h5">
          {t(
            'prepaymentCalculator.howPaymentChargesAreCalculated.interestRateDifferentialFormula.heading'
          )}
        </h3>
        <p>
          <Trans
            i18nKey="prepaymentCalculator.howPaymentChargesAreCalculated.interestRateDifferentialFormula.body"
            t={t}
          />
        </p>

        <p>
          <Trans
            i18nKey="prepaymentCalculator.howPaymentChargesAreCalculated.footerBlock"
            t={t}
            values={{
              tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
            }}
          >
            {getMortgageServicingTollFreeNumber()}
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default prepaymentCalculator;
